<script>
import {computed, defineComponent, onMounted, reactive, ref} from "vue";
import {CLIENT} from "@/api/client";
import {byApi} from "@/api/helpers";
import {useRouter} from "vue-router/composables";

export default defineComponent({
  setup() {

    var router = useRouter();

    const headers = [
      {text: 'Year', value: 'surveyYear'},
      {text: 'ID', value: 'id'},
      {text: 'Firstname', value: 'firstname'},
      {text: 'Lastname', value: 'lastname'},
      {text: 'Actions', value: 'actions'}
    ];

    const isLoading = ref(true);
    const entries = ref([]);

    onMounted(() => {
      CLIENT.go(byApi('dfg_survey_entries')).addPaginationEnabledModifier(false).get().then(result => {
        entries.value = result.asPaginationResult().membersData();
      }).finally(() => {
        isLoading.value = false;
      })
    })

    function show(item) {
      router.push({name: 'admin-dfg-show', params: {dfgId: item.id}});
    }

    const searchQuery = reactive({
      firstname: '',
      lastname: ''
    });

    const filteredItems = computed(() => {
      return entries.value.filter(value => value.firstname.toLowerCase().startsWith(searchQuery.firstname.toLowerCase()));
    });

    return {headers, isLoading, entries, show, searchQuery, filteredItems}
  }
});
</script>

<template>
  <div class="pa-2">
    <v-card v-if="!isLoading">

      <div>
        <v-data-table
            :headers="headers"
            :items="filteredItems"
        >
          <template v-slot:top>
            <div class="d-flex justify-space-between">
              <v-text-field
                  v-model="searchQuery.firstname"
                  label="Search Firstname"
                  class="mx-4"
              ></v-text-field>
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn color="green" @click="show(item)">Show</v-btn>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>

<style scoped>

</style>